import './styles/App.css';
import twitterLogo from './assets/twitter-logo.svg';
import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import myEpicNft from './utils/MyEpicNFT.json';

const TWITTER_HANDLE = 'thomas_duchamp';
const TWITTER_LINK = `https://twitter.com/${TWITTER_HANDLE}`;

const CONTRACT_ADDRESS = process.env.REACT_APP_RINKEBY_CONTRACT_ADDRESS;

const App = () => {

    const [currentAccount, setCurrentAccount] = useState("");
    const [minting, setMinting] = useState(false);
    const [svgContent, setSvgContent] = useState();
    const [openseaLink, setOpenseaLink] = useState();

    const checkIfWalletIsConnected = async () => {
      const { ethereum } = window;

      if (!ethereum) {
          console.log("Make sure you have metamask!");
          return;
      } else {
          console.log("We have the ethereum object", ethereum);
      }

      const accounts = await ethereum.request({ method: 'eth_accounts' });

      if (accounts.length !== 0) {
          const account = accounts[0];
          console.log("Found an authorized account:", account);
          setCurrentAccount(account)

          // Setup listener! This is for the case where a user comes to our site
          // and ALREADY had their wallet connected + authorized.
          setupEventListener()
      } else {
          console.log("No authorized account found")
      }
  }

  /*
  * Implement your connectWallet method here
  */
  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert("Get MetaMask!");
        return;
      }

      /*
      * Fancy method to request access to account.
      */
      const accounts = await ethereum.request({ method: "eth_requestAccounts" });

      /*
      * Boom! This should print out public address once we authorize Metamask.
      */
      console.log("Connected", accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (error) {
      console.log(error)
    }
  }

  // Setup our listener.
  const setupEventListener = async () => {
    // Most of this looks the same as our function askContractToMintNft
    try {
      const { ethereum } = window;

      if (ethereum) {
        // Same stuff again
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, myEpicNft.abi, signer);

        // THIS IS THE MAGIC SAUCE.
        // This will essentially "capture" our event when our contract throws it.
        // If you're familiar with webhooks, it's very similar to that!
        connectedContract.on("NewEpicNFTMinted", (from, tokenId) => {
          console.log(from, tokenId.toNumber())
          alert(`Hey there! We've minted your NFT and sent it to your wallet. It may be blank right now. It can take a max of 10 min to show up on OpenSea. Here's the link: https://testnets.opensea.io/assets/${CONTRACT_ADDRESS}/${tokenId.toNumber()}`)
        });

        console.log("Setup event listener!")

      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error)
    }
  }

  const askContractToMintNft = async () => {
    try {
      const { ethereum } = window;
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, myEpicNft.abi, signer);

        console.log(parseInt(await connectedContract.getMintedNft()), 'here');
        // console.log('bal',provider.getBalance(CONTRACT_ADDRESS).then(b => console.log(parseInt(b))));
        let nftTxn = await connectedContract.makeAnEpicNFT();
        setMinting(true);
        nftTxn.wait();
        console.log(`Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);

        let mintedSupply = parseInt(await connectedContract.getMintedNft());
        let totalSupply = parseInt(await connectedContract.getTotalSupply());

        connectedContract.on("NewEpicNFTMinted", async (from, tokenId) => {
          setMinting(false);
          let nftTxn = await connectedContract.tokenURI(tokenId.toNumber());
          let obj = (atob(nftTxn.replace("data:application/json;base64,","")));
          setSvgContent(JSON.parse(obj).image);
          setOpenseaLink(`https://testnets.opensea.io/assets/${CONTRACT_ADDRESS}/${tokenId.toNumber()}`);
          console.log(from, tokenId.toNumber());
          alert(`current nft supply: ${mintedSupply + 1} / ${totalSupply}`)
        })
      } else {
        console.log("no eth")
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    checkIfWalletIsConnected();
  })

  const renderNotConnectedContainer = (msg) => (
      <>
        {currentAccount === "" ? (
          <button onClick={connectWallet} className="cta-button connect-wallet-button">
            Connect to Wallet
          </button>
      ) : (
          <button onClick={askContractToMintNft} className="cta-button connect-wallet-button">
            {minting ? "Minting..." : msg }
          </button>
      )}
      </>

  );
  /*
  * Added a conditional render! We don't want to show Connect to Wallet if we're already conencted :).
  */
  return (
    <div className="App">
      <div className="container">
        <div className="header-container">
          <p className="header gradient-text">My Gangsta NFT Collection</p>
          <p> 🐯 </p>
          <p className="sub-text">
            Each unique. Each beautiful. Discover your NFT today.
          </p>
          { svgContent &&
            <a
                className="footer-text"
                href={openseaLink}
                target="_blank"
                rel="noreferrer"
          > See on OpenSea </a>
          }
          <div>
            {svgContent &&  <img width={350} alt={"a great NFT to remember!"} src={svgContent}/>}

          </div>
          <div>
            {svgContent ? renderNotConnectedContainer("Mint Again") : renderNotConnectedContainer("Mint NFT")}

          </div>
        </div>
        <div className="footer-container">
          <img alt="Twitter Logo" className="twitter-logo" src={twitterLogo} />
          <a
            className="footer-text"
            href={TWITTER_LINK}
            target="_blank"
            rel="noreferrer"
          >{`built on @${TWITTER_HANDLE}`}</a>
        </div>
      </div>
    </div>
  );
};

export default App;
